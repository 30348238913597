<template>
  <div>
    <!-- edit dialog -->
    <v-dialog v-model="salesParsonRoleDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Role
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedRole"
            :items="roles"
            item-value="id"
            item-text="name"
            label="Select Role"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitRole(selectedRole)">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog -->
    <v-dialog v-model="statusDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Status
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedStatus"
            :items="status"
            label="Select status"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="onStatusChange(selectedStatus)">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog -->
    <v-dialog v-model="editSalesPersonDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Member
        </v-card-title>
        <v-form style="width: 100%" ref="form" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="editSalesPerson.email"
              label="Email"
              required
              disabled
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-model="editSalesPerson.name"
              :rules="rules.name"
              label="Name"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-model="editSalesPerson.mobilePhone"
              :rules="rules.contact"
              label="Contact"
              required
            ></v-text-field>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="doEditSealsPerson()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete role -->
    <v-dialog v-model="deleteRoleDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" color="red" primary-title>
          Alert
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text class="mt-5">
          Are you sure you want to delete this role?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDeleteRole()"> Yes </v-btn>
          <v-btn color="primary" text @click="deleteRoleDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>
    <!-- add salesperson dialog box -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Member
        </v-card-title>
        <v-card-text>
          <v-form style="width: 100%" ref="form" lazy-validation>
            <v-text-field
              v-model="dummySalepersonIn.spEmail"
              :rules="rules.email"
              label="Email"
            ></v-text-field>
            <v-select
              v-model="dummySalepersonIn.roleId"
              :rules="rules.role"
              :items="inviteRoles"
              item-value="id"
              item-text="name"
              label="Select Role"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="doAddSalesperson(dummySalepersonIn)"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div style="display: flex; justify-content: space-between"> -->
    <v-conatiner>
      <v-row class="d-flex justify-space-between">
        <div class="pr-2 pl-3">
          <h3 class="mb-3">Members</h3>
          <v-text-field
            v-on:keyup="searchSalePerson(searchSalesPerson)"
            v-model="searchSalesPerson"
            prepend-inner-icon="mdi-magnify"
            solo
            label="Search Member"
          ></v-text-field>
        </div>
        <v-btn class="mx-2" fab small @click="addSalePersonBtn()">
          <v-icon color="indigo">mdi-plus</v-icon>
        </v-btn>
      </v-row>
    </v-conatiner>
    <!-- </div> -->
    <div>
      <v-simple-table height="300px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Contact</th>
              <th class="text-left">Email</th>
              <th class="text-left">Status</th>
              <th class="text-left">Role</th>
              <th class="text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in listSalePerson" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.roleName ? item.roleName : "Un-assign" }}</td>
              <td>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list dense>
                      <v-list-item-group color="primary">
                        <v-list-item
                          v-for="(option, i) in optionBtns"
                          :key="i"
                          @click="optionSelected(option.value, item)"
                          :disabled="
                            item.salePersonType === 'MASTER' &&
                              (option.value == 'assign_role' ||
                                option.value == 'delete')
                          "
                        >
                          <v-list-item-icon>
                            <v-icon v-text="option.icon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="option.title"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import putAxios from "services/axios-put";
import postAxios from "services/axios-post";
import deleteAxios from "services/axios-delete";
import getAxios from "../../../services/axios-get";
import patchAxios from "../../../services/axios-patch";
// import getAxios from "services/axios-get";
Vue.use(VueAxios, axios);
export default {
  props: {
    dealerId: String,
  },
  data() {
    return {
      type: null,
      alertMsg: null,
      salespersonList: [],
      listSalePerson: [],
      searchSalesPerson: null,
      status: ["ACTIVE", "INACTIVE"],
      optionBtns: [
        { value: "edit", title: "Edit", icon: "mdi-pencil" },
        {
          value: "suspend_active",
          title: "Suspend/Active",
          icon: "mdi-minus-box",
        },
        {
          value: "assign_role",
          title: "Assign Role",
          icon: "mdi-account-plus",
        },
        { value: "delete", title: "Delete", icon: "mdi-delete" },
      ],
      dummySalepersonIn: {
        dealerId: this.dealerId,
        name: null,
        phone: null,
        mobilePhone: null,
        spEmail: null,
        roleId: null,
      },
      editSalesPerson: {
        dealerId: this.dealerId,
        id: null,
        name: null,
        phone: null,
        mobilePhone: null,
        email: null,
        status: null,
        dealerCreditBalance: 0,
      },
      roles: [],
      inviteRoles: [],
      selectedRole: null,
      selectedStatus: null,
      dialog: false,
      deleteRoleDialog: false,
      editSalesPersonDialog: false,
      salesParsonRoleDialog: false,
      statusDialog: false,
      selectedMember: null,
      rules: {
        email: [
          v =>
            //eslint-disable-next-line
            /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        role: [v => !!v || "Role required"],
        name: [v => !!v || "Name required"],
        contact: [v => !!v || "Contact required"],
      },
      delSalePerson: {
        id: "",
        name: "",
      },
      statusUpdateSalesPersonId: null,
    };
  },
  computed: {},
  mounted() {
    this.fetchSalespersonList(this.dealerId);
  },
  watch: {
    dealerId(newDealerId) {
      this.assignedSalespersonList = [];
      this.fetchSalespersonList(newDealerId);
    },
  },
  methods: {
    searchSalePerson(value) {
      const salePersons = [...this.salespersonList];
      if (value) {
        this.listSalePerson = salePersons.filter(salesperson => {
          return value
            .toLowerCase()
            .split(" ")
            .every(v => salesperson.name.toLowerCase().includes(v));
        });
      } else {
        this.listSalePerson = [...this.salespersonList];
        return this.listSalePerson;
      }
    },
    addSalePersonBtn() {
      this.dialog = true;
      this.getRoleList(this.dealerId);
    },
    getRoleList(dealer) {
      if (dealer) {
        const params = {
          dealerId: dealer,
        };
        getAxios(
          `${process.env.VUE_APP_SERVER_URL}/roleSystem/rolesByDealer`,
          params
        ).then(res => {
          if (res.data) {
            this.inviteRoles = [...res.data.data];
            this.roles = res.data.data;
            this.roles.push({
              id: null,
              name: "Un-Assign",
            });
          }
        });
      }
    },
    optionSelected(optionbtn, salesPerson) {
      if (optionbtn === "edit") {
        this.editSalesPerson.id = salesPerson.id ?? null;
        this.editSalesPerson.email = salesPerson.email ?? null;
        this.editSalesPerson.phone = salesPerson.phone ?? null;
        this.editSalesPerson.name = salesPerson.name ?? null;
        this.editSalesPerson.mobilePhone = salesPerson.mobilePhone ?? null;
        this.editSalesPerson.status = salesPerson.status ?? null;
        this.editSalesPerson.dealerCreditBalance =
          salesPerson.dealerCreditBalance ?? null;
        this.editSalesPersonDialog = true;
      } else if (optionbtn === "suspend_active") {
        this.statusDialog = true;
        this.selectedStatus = salesPerson.status;
        this.statusUpdateSalesPersonId = salesPerson.id;
      } else if (optionbtn === "assign_role") {
        this.getRoleList(this.dealerId);
        this.selectedMember = salesPerson;
        this.selectedRole = salesPerson.roleId ? salesPerson.roleId : null;
        this.salesParsonRoleDialog = true;
      } else if (optionbtn === "delete") {
        this.delSalePerson.id = salesPerson.id;
        this.delSalePerson.name = salesPerson.name;
        this.deleteRoleDialog = true;
      }
    },
    deleteRoleMember() {
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/roleSystem/delRoleSalesperson?salespersonId=${this.selectedMember.id}`,
        this.token
      )
        .then(res => {
          if (res.data) {
            this.showAlert("success", "Remove role successfully");
            this.fetchSalespersonList(this.dealerId);
          }
        })
        .catch(err => {
          console.error(err);
          this.showAlert("error", err);
        });
      this.selectedMember = null;
      this.salesParsonRoleDialog = false;
    },
    updateRoleMember(role) {
      patchAxios(
        `${process.env.VUE_APP_SERVER_URL}/roleSystem/updateRoleMember?salespersonId=${this.selectedMember.id}&newRoleId=${role}`
      )
        .then(res => {
          if (res.data) {
            this.showAlert("success", "Update role successfully");
            this.fetchSalespersonList(this.dealerId);
          }
        })
        .catch(err => {
          console.error(err);
          this.showAlert("error", err);
        });
    },
    submitRole(role) {
      if (!role) {
        this.deleteRoleMember();
      } else if (!this.selectedMember.roleId) {
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/roleSystem/addRoleSalesperson?salespersonId=${this.selectedMember.id}&roleId=${role}`
        )
          .then(res => {
            if (res.data) {
              this.showAlert("success", "Assign role successfully");
              this.fetchSalespersonList(this.dealerId);
            }
          })
          .catch(err => {
            console.error(err);
            this.showAlert("error", err);
          });
      } else if (this.selectedMember.roleId) {
        this.updateRoleMember(role);
      }
      this.selectedMember = null;
      this.salesParsonRoleDialog = false;
    },
    doEditSealsPerson() {
      if (this.$refs.form.validate()) {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/dealerSystem/updSaleperson?dealerId=${this.dealerId}&dealerSalePersonUpdIn=${this.editSalesPerson.id}`,
          this.editSalesPerson
        )
          .then(res => {
            console.log(res);
            this.showAlert("success", "Updated successfully");
            this.fetchSalespersonList(this.dealerId);
            this.resetEditSaleperson();
            this.editSalesPersonDialog = false;
          })
          .catch(err => {
            console.log(err);
            this.showAlert("error", "failed..");
          });
      }
    },
    doDeleteRole() {
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealerSystem/delSaleperson?salepersonId=${this.delSalePerson.id}`
      )
        .then(res => {
          console.log(res);
          this.showAlert("success", "Deleted successfully");
          this.fetchSalespersonList(this.dealerId);
          this.deleteRoleDialog = false;
          this.resetdelSalePerson();
        })
        .catch(err => {
          console.log(err);
          this.showAlert("error", "failed..");
        });
    },
    resetdelSalePerson() {
      this.delSalePerson.id = "";
      this.delSalePerson.name = "";
    },
    onStatusChange(status) {
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealerSystem/updSalepersonStatus?salepersonId=${this.statusUpdateSalesPersonId}&status=${status}`
      )
        .then(res => {
          console.log(res);
          this.showAlert("success", "Edited successfully");
          this.fetchSalespersonList(this.dealerId);
          this.statusUpdateSalesPersonId = null;
          this.selectedStatus = null;
          this.statusDialog = false;
        })
        .catch(err => {
          console.log(err);
          this.showAlert("error", "failed..");
        });
    },
    fetchSalespersonList(dealerId) {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealerInfo/dealerSaleperson?size=100&dealerId=${dealerId}`
      )
        .then(res => {
          const salePersons = res.data.data.content;
          self.salespersonList = salePersons;
          self.listSalePerson = salePersons;
        })
        .catch(err => {
          console.error(err);
        });
    },
    resetdummySaleperson() {
      this.dummySalepersonIn.name = null;
      this.dummySalepersonIn.phone = null;
      this.dummySalepersonIn.mobilePhone = null;
      this.dummySalepersonIn.spEmail = null;
      this.dummySalepersonIn.roleId = null;
    },
    resetEditSaleperson() {
      this.editSalesPerson.id = null;
      this.editSalesPerson.name = null;
      this.editSalesPerson.phone = null;
      this.editSalesPerson.mobilePhone = null;
      this.editSalesPerson.email = null;
      this.editSalesPerson.status = null;
      this.editSalesPerson.dealerCreditBalance = null;
    },
    doAddSalesperson(dummySalepersonIn) {
      dummySalepersonIn.mobilePhone = dummySalepersonIn.phone;
      if (this.$refs.form.validate()) {
        let queryParams = `dealerId=${dummySalepersonIn.dealerId}&roleId=${
          dummySalepersonIn.roleId
        }&spEmail=${encodeURIComponent(dummySalepersonIn.spEmail)}`;
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/dealerSystem/inviteSaleperson?${queryParams}`
        )
          .then(res => {
            if (res) {
              this.dialog = false;
              this.showAlert("success", "Invitetion send successfully..");
              this.fetchSalespersonList(this.dealerId);
              this.resetdummySaleperson();
            }
          })
          .catch(err => {
            console.log(err);
            this.showAlert("error", "Invitation already exists!");
            this.dialog = false;
          });
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import putAxios from "services/axios-put";
import deleteAxios from "services/axios-delete";
import getAxios from "services/axios-get";

Vue.use(VueAxios, axios);

export default {
  props: {
    dealerId: String,
  },
  data() {
    return {
      type: null,
      alertMsg: null,
      variantList: [],
      actionItems: ["Save", "Remove"],
      dialog: false,
      isLoading: false,
    };
  },
  computed: {},
  mounted() {
    this.fetchVariantList(this.dealerId);
  },
  methods: {
    onActionChange(item) {
      const status = item.status;
      if (status.includes("Remove")) {
        deleteAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/delCarModelFrontVariant?carModelFrontVariantId=${item.id}`
        )
          .then(res => {
            console.log(res);
            this.showAlert("success", "Deleted successfully");
            this.fetchVariantList(this.dealerId);
          })
          .catch(err => {
            console.log(err);
            this.showAlert("error", "failed..");
          });
      } else if (status.includes("Save")) {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/updCarModelFrontVariant`,
          item
        )
          .then(res => {
            console.log(res);
            this.showAlert("success", "Updated successfully");
            this.fetchVariantList(this.dealerId);
          })
          .catch(err => {
            console.log(err);
            this.showAlert("error", "failed..");
          });
      }
    },
    fetchVariantList(dealerId) {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/carModelFrontVariantByDealer?dealerId=${dealerId}`
      )
        .then(res => {
          self.variantList = res.data.data;
        })
        .catch(err => {
          console.error(err);
        });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};

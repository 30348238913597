<template>
  <div>
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>
    <div
      style="    display: flex;
    justify-content: space-between;"
    >
      <!-- add show room dialog box -->
      <v-dialog v-model="editDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Add Show Room
          </v-card-title>
          <br />
          <v-card-text>
            <v-text-field
              v-model="showRoomUpdIn.name"
              label="Name"
            ></v-text-field>
            <v-text-field
              v-model="showRoomUpdIn.address"
              label="address"
            ></v-text-field>
            <v-text-field
              v-model="showRoomUpdIn.contactNumber"
              label="Contact Number"
            ></v-text-field>
            <v-text-field
              v-model="showRoomUpdIn.operationalHrs"
              label="Operational Hrs"
            ></v-text-field>
            <v-text-field
              v-model="showRoomUpdIn.facebookUrl"
              label="Facebook Url"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="doEditShowRoom(showRoomUpdIn)"
              >Edit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- add show room dialog box -->
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Add Show Room
          </v-card-title>
          <br />
          <v-card-text>
            <v-text-field v-model="showRoomIn.name" label="Name"></v-text-field>
            <v-text-field
              v-model="showRoomIn.address"
              label="address"
            ></v-text-field>
            <v-text-field
              v-model="showRoomIn.contactNumber"
              label="Contact Number"
            ></v-text-field>
            <v-text-field
              v-model="showRoomIn.operationalHrs"
              label="Operational Hrs"
            ></v-text-field>
            <v-text-field
              v-model="showRoomIn.facebookUrl"
              label="Facebook Url"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="doAddShowRoom(showRoomIn)"
              >Add</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <h3>Show Rooms</h3>
      <v-btn class="mx-2" fab small @click="dialog = true">
        <v-icon color="indigo">mdi-plus</v-icon>
      </v-btn>
    </div>
    <br />
    <div>
      <v-simple-table height="300px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Address
              </th>
              <th class="text-left">
                Contact Number
              </th>
              <th class="text-left">
                Operational Hrs
              </th>
              <th class="text-left">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in showRoomList" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.contactNumber }}</td>
              <td>{{ item.operationalHrs }}</td>
              <td>
                <v-select
                  v-model="item.status"
                  :items="actionItems"
                  label="action"
                  persistent-hint
                  return-object
                  single-line
                  @change="onActionChange(item)"
                ></v-select>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import putAxios from "services/axios-put";
import postAxios from "services/axios-post";
import deleteAxios from "services/axios-delete";
import getAxios from "services/axios-get";

Vue.use(VueAxios, axios);

export default {
  props: {
    dealerId: String,
  },
  data() {
    return {
      item: {
        status: "",
      },
      type: null,
      alertMsg: null,
      showRoomList: [],
      actionItems: ["Edit", "Remove"],
      showRooms: null,
      dialog: false,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      showRoomIn: {
        dealerId: this.dealerId,
        name: null,
        address: null,
        contactNumber: null,
        operationalHrs: null,
        facebookUrl: null,
      },
      editDialog: false,
      showRoomUpdIn: {
        id: null,
        name: null,
        address: null,
        contactNumber: null,
        operationalHrs: null,
        facebookUrl: null,
      },
    };
  },
  computed: {},
  mounted() {
    this.fetchShowRoomList(this.dealerId);
  },
  watch: {
    dealerId(newParentDealerId) {
      this.fetchShowRoomList(newParentDealerId);
    },
  },
  methods: {
    onActionChange(item) {
      const status = item.status;
      if (status.includes("Remove")) {
        deleteAxios(
          `${process.env.VUE_APP_SERVER_URL}/outletSystem/delShowRoom?showRoomId=${item.id}`
        )
          .then(res => {
            console.log(res);
            this.showAlert("success", "Deleted successfully");
            this.fetchShowRoomList(this.dealerId);
          })
          .catch(err => {
            console.log(err);
            this.showAlert("error", "failed..");
          });
      } else if (status.includes("Edit")) {
        this.showRoomUpdIn.id = item.id;
        this.showRoomUpdIn.name = item.name;
        this.showRoomUpdIn.address = item.address;
        this.showRoomUpdIn.contactNumber = item.contactNumber;
        this.showRoomUpdIn.operationalHrs = item.operationalHrs;
        this.showRoomUpdIn.facebookUrl = item.facebookUrl;
        this.editDialog = true;
      }
    },
    fetchShowRoomList(dealerId) {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/outletSystem/showRoom`, {
        dealerId: dealerId,
      })
        .then(res => {
          self.showRoomList = res.data.data.content;
          console.log(self.showRoomList);
        })
        .catch(err => {
          console.error(err);
        });
    },
    doAddShowRoom(showRoomIn) {
      postAxios(
        `${process.env.VUE_APP_SERVER_URL}/outletSystem/addShowRoom`,
        showRoomIn
      )
        .then(res => {
          console.log(res);
          this.showAlert("success", "Added successfully");
          this.fetchShowRoomList(this.dealerId);
          this.dialog = !this.dialog;
        })
        .catch(err => {
          console.log(err);
          this.showAlert("error", "failed..");
        });
    },
    doEditShowRoom(showRoomUpdIn) {
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/outletSystem/updShowRoom`,
        showRoomUpdIn
      )
        .then(res => {
          console.log(res);
          this.showAlert("success", "Edited successfully");
          this.fetchShowRoomList(this.dealerId);
          this.editDialog = !this.editDialog;
        })
        .catch(err => {
          console.log(err);
          this.showAlert("error", "failed..");
        });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>

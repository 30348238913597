<template>
  <div class="dealer">
    <v-dialog v-model="subscriptionDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Assign Subscription Plan
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form style="width: 100%" ref="form" lazy-validation>
              <v-row>
                <v-col>
                  <v-select
                    required
                    :rules="[v => !!v || 'Select Subscription Plan']"
                    :items="subscriptions"
                    label="Subscription Plan"
                    v-model="dealerSubscriptionData.dealerSubscriptionId"
                    item-value="id"
                    item-text="name"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-dialog
                    ref="dialog"
                    v-model="subscriptionStartDateModel"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dealerSubscriptionData.planStartDate"
                        label="Subscription start date"
                        required
                        :rules="[v => !!v || 'Start Date required']"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dealerSubscriptionData.planStartDate"
                      scrollable
                      :max="maxDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          selectedStartDate(
                            dealerSubscriptionData.planStartDate
                          )
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-dialog
                    ref="dialog"
                    v-model="subscriptionEndDateModel"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dealerSubscriptionData.planEndDate"
                        label="Subscription end date"
                        required
                        :rules="[v => !!v || 'End Date required']"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dealerSubscriptionData.planEndDate"
                      scrollable
                      :min="minDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          selectedEndDate(dealerSubscriptionData.planEndDate)
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="doAssignSubscription()">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="associatedCarBrandsDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Update Associated Car Brands
        </v-card-title>
        <v-virtual-scroll
          :bench="benched"
          :items="dealerCarBrandsList"
          height="300"
          item-height="64"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.carBrandId">
              <v-list-item-content>
                <img
                  style="height: 50px; width: 50px; flex: none"
                  v-if="item.carBrandImgUrl != null"
                  :src="item.carBrandImgUrl"
                />
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ item.carBrandName }}</strong>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  fab
                  small
                  depressed
                  color="red"
                  @click="deleteFromCarBrandList(item)"
                >
                  <v-icon medium color="white"> mdi-trash-can-outline </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>

        <v-card-text>
          <v-autocomplete
            v-model="selectedCarBrand"
            :items="carBrandSearchEntries"
            :search-input.sync="search"
            :loading="isLoading"
            prepend-icon="mdi-database-search"
            return-object
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-text>
          <v-btn
            style="float: right"
            small
            depressed
            color="primary"
            @click="addDealerCarBrand(selectedCarBrand)"
          >
            <v-icon medium color="white"> mdi-plus </v-icon>
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              doUpdateDealerCarBrands(editDealer.dealerId, dealerCarBrandsList)
            "
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete <b>{{ delAdDealer.name }}</b> from AD
          Dealer?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="doDelAdDealer()"> Yes </v-btn>
          <v-btn text @click="deleteDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add dialog -->
    <v-dialog v-model="addDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add {{ addDealer.name }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-container style="padding: 30px">
            <v-row>
              <v-col>
                <v-row>
                  <v-text-field
                    v-model="addDealer.name"
                    label="Name"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.locality"
                    label="Locality"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-file-input
                    label="Logo"
                    v-model="addDealer.logoPath"
                    clearable
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                  ></v-file-input>
                </v-row>
                <v-row>
                  <v-textarea
                    v-model="addDealer.description"
                    label="Description"
                    :rows="1"
                    auto-grow
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.mobilePhone"
                    label="Contact"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.address"
                    label="Address"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-textarea
                    v-model="addDealer.operationalHrs"
                    label="Operational Hrs"
                    :rows="1"
                    auto-grow
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.uenNum"
                    label="UEN"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.facebookUrl"
                    label="Facebook Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.websiteUrl"
                    label="Website Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.youtubeUrl"
                    label="Youtube Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.instagramUrl"
                    label="Instagram Url"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    v-model="addDealer.whatsappUrl"
                    label="Whatsapp Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.bookTestDriveUrl"
                    label="Book Test Drive Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.creditBalance"
                    label="Credit Balance"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-switch
                    label="Authorized Dealer"
                    v-model="addDealer.authorizedFlag"
                  ></v-switch>
                </v-row>
                <v-row>
                  <v-switch
                    label="Hide Car"
                    v-model="addDealer.hideCarFlag"
                  ></v-switch>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col style="display: flex; flex-flow: column-reverse">
                    <v-file-input
                      label="Branding Banner"
                      v-model="addDealer.primaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="display: flex; flex-flow: column-reverse">
                    <v-file-input
                      label="Branding Banner (Mobile)"
                      v-model="addDealer.mobileListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="display: flex; flex-flow: column-reverse">
                    <v-file-input
                      label="Branding Banner (ipad)"
                      v-model="addDealer.ipadListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col style="display: flex; flex-flow: column-reverse">
                    <v-file-input
                      label="Side Banner"
                      v-model="addDealer.secondaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col style="display: flex; flex-flow: column-reverse">
                    <v-file-input
                      label="Large Top Banner"
                      v-model="addDealer.tertiaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col>
                    <div style="border: 1px dotted black; padding: 12px;">
                      <DealerSalespersonGroupInput
                        :dealerId="editDealer.dealerId"
                      />
                    </div>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>

            <v-row>
              <!-- <v-btn
                text
                color="primary"
                @click="showDealerCarBrands(editDealer.dealerId)"
              >
                Associated Car Brands
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="doAddDealer(addDealer)"
                >Submit</v-btn
              >
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-container style="padding: 30px">
            <v-row>
              <v-col>
                <v-row>
                  <v-text-field
                    v-model="editDealer.name"
                    label="Name"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.locality"
                    label="Locality"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-textarea
                    v-model="editDealer.description"
                    label="Description"
                    :rows="1"
                    auto-grow
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.mobilePhone"
                    label="Contact"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.address"
                    label="Address"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-textarea
                    v-model="editDealer.operationalHrs"
                    label="Operational Hrs"
                    :rows="1"
                    auto-grow
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.uenNum"
                    label="UEN"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.facebookUrl"
                    label="Facebook Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.websiteUrl"
                    label="Website Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.youtubeUrl"
                    label="Youtube Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.instagramUrl"
                    label="Instagram Url"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    v-model="editDealer.whatsappUrl"
                    label="Whatsapp Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.bookTestDriveUrl"
                    label="Book Test Drive Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.email"
                    label="email"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.creditBalance"
                    label="Credit Balance"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <div
                    style="
                      border: 1px dotted black;
                      padding: 12px;
                      width: -webkit-fill-available;
                    "
                  >
                    <ShowRoomGroupInput :dealerId="editDealer.dealerId" />
                  </div>
                </v-row>
                <v-row>
                  <v-switch
                    label="Authorized Dealer"
                    v-model="editDealer.authorizedFlag"
                  ></v-switch>
                </v-row>
                <v-row>
                  <v-switch
                    label="Hide Car"
                    v-model="editDealer.hideCarFlag"
                  ></v-switch>
                </v-row>
                <v-row>
                  <v-switch
                    label="Deactivate Dealer"
                    v-model="editDealer.deactivationFlag"
                  ></v-switch>
                </v-row>
              </v-col>

              <v-col>
                <v-row>
                  <v-col style="display: flex; flex-flow: column-reverse">
                    <img
                      style="height: 50px; width: fit-content"
                      v-if="primaryListingBannerDisplay != null"
                      :src="primaryListingBannerDisplay"
                    />
                    <v-file-input
                      label="Branding Banner"
                      v-model="editDealer.primaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="display: flex; flex-flow: column-reverse">
                    <img
                      style="height: 50px; width: fit-content"
                      v-if="mobileListingBannerDisplay != null"
                      :src="mobileListingBannerDisplay"
                    />
                    <v-file-input
                      label="Branding Banner (mobile)"
                      v-model="editDealer.mobileListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="display: flex; flex-flow: column-reverse">
                    <img
                      style="height: 50px; width: fit-content"
                      v-if="ipadListingBannerDisplay != null"
                      :src="ipadListingBannerDisplay"
                    />
                    <v-file-input
                      label="Branding Banner (ipad)"
                      v-model="editDealer.ipadListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="display: flex; flex-flow: column-reverse">
                    <img
                      style="height: 50px; width: fit-content"
                      v-if="secondaryListingBannerDisplay != null"
                      :src="secondaryListingBannerDisplay"
                    />
                    <v-file-input
                      label="Side Banner"
                      v-model="editDealer.secondaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col style="display: flex; flex-flow: column-reverse">
                    <img
                      style="height: 50px; width: fit-content"
                      v-if="tertiaryListingBannerDisplay != null"
                      :src="tertiaryListingBannerDisplay"
                    />
                    <v-file-input
                      label="Large Top Banner"
                      v-model="editDealer.tertiaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-file-input
                      accept=".pdf"
                      label="Price List file"
                      v-model="editDealer.priceListPath"
                    ></v-file-input>
                    <v-btn
                      v-if="priceListPathDisplay"
                      small
                      elevation
                      color="green"
                      style="color: white; float: right"
                      @click="clickPriceList(priceListPathDisplay)"
                    >
                      Show Price List
                    </v-btn>
                    <v-row>
                      <v-text-field
                        v-model="editDealer.priceListEffectiveDate"
                        label="Price List Effective Date"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div style="border: 1px dotted black; padding: 12px">
                      <DealerSalespersonGroupInput
                        :dealerId="editDealer.dealerId"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div style="border: 1px dotted black; padding: 12px">
                      <CarFrontVariantDealerGroup
                        :dealerId="editDealer.dealerId"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-btn
                text
                color="primary"
                @click="showDealerCarBrands(editDealer.dealerId)"
              >
                Associated Car Brands
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shortcutEditDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Status
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="editDealer.status"
            :items="dealerStatusList"
            item-value="value"
            item-text="text"
            label="Dealer Status"
          >
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editNameDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Name
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="editDealer.name" label="Dealer Name">{{
            editDealer.name
          }}</v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editPhotoDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Profile Picture
        </v-card-title>
        <v-card-text>
          <v-file-input
            label="Logo"
            v-model="imgFile"
            clearable
            accept="image/jpg,image/jpeg,image/png,image/gif"
          ></v-file-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editCrshortcutEditDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} credit balance
        </v-card-title>
        <br />
        <v-card-text>
          <v-text-field
            type="number"
            prefix="$"
            v-model="editDealer.creditBalance"
            label="Credit balance"
            >{{ editDealer.creditBalance }}</v-text-field
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDescriptionDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Description
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="editDealer.description"
            :rows="2"
            auto-grow
            label="Description"
            >{{ editDealer.description }}</v-textarea
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="assignPlatformDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Assign {{ assignPlatformData.name }} Platforms
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-for="platform in platformList"
            :key="platform.id"
            v-model="assignPlatformData.platformIds"
            :label="platform.name"
            :value="platform.id"
          ></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="doAssignPlatforms"
            :disabled="assignPlatformData.platformIds.length === 0"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="assignDealerTypeDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Assign {{ assignDealerTypeData.name }} Type
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-for="type in dealerTypeList"
            :key="type.id"
            v-model="assignDealerTypeData.dealerTypeId"
            :label="type.dealerType"
            :value="type.id"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="showDealerCarBrands(assignDealerTypeData.dealerId)"
          >
            Associated Car Brands
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="doAssignDealerTypes"
            :disabled="assignDealerTypeData.dealerTypeId.length === 0"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="assignAccountManagerDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Assign Account Manager
        </v-card-title>
        <v-card-text>
          <v-select
            :items="accountManagerList"
            v-model="assignAccountManagerData.accountManagerId"
            item-value="id"
            item-text="name"
            label="Select Account Manager"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="doAssignAccountManager"
            :disabled="assignAccountManagerData.accountManagerId === ''"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchDealer()"
          v-model="dealerSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          v-model="dealerStatus"
          :items="dealerStatusList"
          item-value="value"
          item-text="text"
          label="Car Status"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchDealer()"
          @keyup.enter.native="searchDealer()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
      <v-btn fab color="red accent-2" @click="addDialog = true">
        <v-icon color="#fff">mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="dealerList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-btn class="mx-2" dark small @click="editNameButtonClick(item)">
          {{ item.name }}
        </v-btn>
      </template>
      <template v-slot:[`item.logoPath`]="{ item }">
        <v-btn class="mx-2" dark small @click="editPhotoButtonClick(item)">
          <img
            style="height: 50px"
            v-if="item.logoPath != null"
            :src="item.logoPath"
          />
        </v-btn>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-btn
          class="mx-2"
          dark
          small
          :color="
            item.status === 'REJECTED'
              ? 'error'
              : item.status === 'PENDING_APPROVAL'
              ? 'warning'
              : 'primary'
          "
          @click="editStatusButtonClick(item)"
        >
          {{ item.status }}
        </v-btn>
      </template>
      <template v-slot:[`item.accountManager`]="{ item }">
        <v-btn class="mx-2" dark small @click="assignAccountManagerClick(item)">
          {{ item.accountManagerName ? item.accountManagerName : "null" }}
        </v-btn>
      </template>
      <template v-slot:[`item.platforms`]="{ item }">
        <v-btn class="mx-2" dark small @click="assignPlatformClick(item)">
          {{ item.platforms.map(p => p.name).join(",") }}
        </v-btn>
      </template>
      <template v-slot:[`item.dealerSubscription`]="{ item }">
        <v-btn class="mx-2" dark small @click="assignDealerSubscription(item)">
          {{ item.dealerSubscription ? item.dealerSubscription.name : "---" }}
        </v-btn>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="primary"
          @click="editButtonClick(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="red"
          @click="deleteButtonClick(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import uploadImage from "../../../services/image-uploader";
import uploadPriceListPdf from "services/priceList-uploader";
import DealerSalespersonGroupInput from "@/components/DealerSalespersonGroupInput";
import ShowRoomGroupInput from "@/components/ShowRoomGroupInput";
import CarFrontVariantDealerGroup from "@/components/CarFrontVariantDealerGroup";

import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";
import postAxios from "../../../services/axios-post";
import deleteAxios from "../../../services/axios-delete";
import patchAxios from "../../../services/axios-patch";
import moment from "moment";
Vue.use(VueAxios, axios);

export default {
  components: {
    DealerSalespersonGroupInput,
    ShowRoomGroupInput,
    CarFrontVariantDealerGroup,
  },
  data() {
    return {
      priceListPathDisplay: null,
      addDialog: false,
      editDialog: false,
      selectedCarBrand: null,
      isLoading: false,
      dealerCarBrandsList: [],
      benched: 0,
      entries: [],
      search: null,
      dealerSearch: "",
      dealerStatus: "",
      imgFile: null,
      imgUrl: null,
      primaryListingBannerDisplay: null,
      mobileListingBannerDisplay: null,
      ipadListingBannerDisplay: null,
      secondaryListingBannerDisplay: null,
      tertiaryListingBannerDisplay: null,
      pageCount: 0,
      associatedCarBrandsDialog: false,
      shortcutEditDialog: false,
      editNameDialog: false,
      editPhotoDialog: false,
      deleteDialog: false,
      editCrshortcutEditDialog: false,
      editDescriptionDialog: false,
      assignAccountManagerDialog: false,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Logo", value: "logoPath" },
        { text: "Phone", value: "mobilePhone" },
        { text: "Address", value: "address" },
        { text: "UEN", value: "uenNum" },
        { text: "Status", value: "status" },
        { text: "Manager", value: "accountManager" },
        { text: "Platforms", value: "platforms", sortable: false },
        { text: "Subscription", value: "dealerSubscription", sortable: false },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
      dealerList: [],
      editDealer: {
        dealerId: null,
        name: null,
        mobilePhone: null,
        address: null,
        uenNum: null,
        status: null,
        creditBalance: 0,
        logoName: null,
        logoPath: null,
        description: null,
        hideCarFlag: null,
        authorizedFlag: null,
        primaryListingBannerPath: null,
        mobileListingBannerPath: null,
        ipadListingBannerPath: null,
        secondaryListingBannerPath: null,
        tertiaryListingBannerPath: null,
        facebookUrl: null,
        websiteUrl: null,
        locality: null,
        operationalHrs: null,
        priceListPath: null,
        deactivationFlag: null,
        priceListEffectiveDate: null,
        email: null,
        bookTestDriveUrl: null,
      },
      addDealer: {
        name: null,
        mobilePhone: null,
        address: null,
        uenNum: null,
        status: null,
        creditBalance: 0,
        logoName: null,
        logoPath: null,
        description: null,
        hideCarFlag: null,
        authorizedFlag: true,
        primaryListingBannerPath: null,
        mobileListingBannerPath: null,
        ipadListingBannerPath: null,
        secondaryListingBannerPath: null,
        tertiaryListingBannerPath: null,
        facebookUrl: null,
        websiteUrl: null,
        locality: null,
        operationalHrs: null,
        priceListPath: null,
        priceListEffectiveDate: null,
      },
      dealerStatusList: [
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "APPROVED", value: "APPROVED" },
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "REJECTED", value: "REJECTED" },
      ],
      platformList: [],
      dealerTypeList: [],
      accountManagerList: [],
      assignPlatformDialog: false,
      assignDealerTypeDialog: false,
      assignPlatformData: {
        platformIds: [],
        name: "",
        dealerId: "",
      },
      assignDealerTypeData: {
        dealerTypeId: [],
        name: "",
        dealerId: "",
      },
      assignAccountManagerData: {
        accountManagerId: "",
        dealerId: "",
      },
      delAdDealer: {
        id: "",
        name: "",
      },
      sortValue: null,
      sortBy: null,
      sortDesc: null,
      subscriptionEndDateModel: false,
      subscriptionStartDateModel: false,
      subscriptionDialog: false,
      subscriptions: [],
      dealerSubscriptionData: {
        dealerId: null,
        dealerSubscriptionId: null,
        planStartDate: null,
        planEndDate: null,
      },
      minDate: null,
      maxDate: null,
    };
  },
  computed: {
    carBrandSearchEntries() {
      const currectDealerCarBrandIds = this.dealerCarBrandsList.map(
        x => x.carBrandId
      );
      var d = this.entries
        .filter(x => currectDealerCarBrandIds.indexOf(x.id) < 0)
        .map(x => ({
          text: x.name,
          value: x.id,
          disabled: false,
          logoPath: x.logoPath,
          name: x.name,
          id: x.id,
        }));
      return d;
    },
  },
  watch: {
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      console.log("Loading here: " + val);

      // Lazily load input items
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carBrand?searchContent=${val}`
      )
        .then(res => {
          this.entries = res.data.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.fetchDealerList();
    this.fetchPlatforms();
    this.fetchDealerTypes();
    this.fetchAccountManagers();
    this.fetchSubscription();
  },
  methods: {
    // chooseImage() {
    //   this.editDealer.logoName = this.imgFile.name;
    //   this.imgUrl = URL.createObjectURL(this.imgFile);
    // },
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = "createTime,desc";
      }
      this.fetchDealerList();
    },
    convertDate(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD");
      return parsedDate;
    },
    selectedStartDate(date) {
      this.subscriptionStartDateModel = false;
      if (date) {
        const selectedDate = new Date(date);
        const day = selectedDate.getDate();
        const month = selectedDate.getMonth() + 1;
        const year = selectedDate.getFullYear();
        if (day === 1) {
          this.minDate = date;
        } else {
          const minMonth = month === 12 ? 1 : month + 1;
          const minYear = month === 12 ? year + 1 : year;
          const dateMin = minYear + "-" + minMonth + "-" + "1";
          this.minDate = this.convertDate(dateMin);
        }
      }
    },
    selectedEndDate(date) {
      this.subscriptionEndDateModel = false;
      if (date) {
        const selectedDate = new Date(date);
        const month = selectedDate.getMonth() + 1;
        const year = selectedDate.getFullYear();
        const dateMax = year + "-" + month + "-" + "1";
        this.maxDate = this.convertDate(dateMax);
      }
    },
    fetchSubscription() {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-subscription`,
        this.token
      ).then(res => {
        if (res.data) {
          this.subscriptions = res.data.items;
        }
      });
    },
    resetdealerSubscription() {
      this.dealerSubscriptionData.dealerId = null;
      this.dealerSubscriptionData.dealerSubscriptionId = null;
      this.dealerSubscriptionData.planStartDate = null;
      this.dealerSubscriptionData.planEndDate = null;
      this.selectedDealerSubscriptionId = null;
    },
    assignDealerSubscription(item) {
      this.resetdealerSubscription();
      this.dealerSubscriptionData.dealerId = item.id;
      const filter = encodeURI(`[["dealer.id", "eq", "${item.id}"]]`);
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-vs-dealer-subscription?filter=${filter}`
      ).then(res => {
        if (res.data) {
          const dealerSubscriptionData = res.data.items[0];
          this.dealerSubscriptionData.dealerSubscriptionId =
            dealerSubscriptionData.dealerSubscription.id;
          this.dealerSubscriptionData.planStartDate = dealerSubscriptionData.planStartDate
            ? this.convertDate(dealerSubscriptionData.planStartDate)
            : null;
          this.dealerSubscriptionData.planEndDate = dealerSubscriptionData.planEndDate
            ? this.convertDate(dealerSubscriptionData.planEndDate)
            : null;
          this.selectedDealerSubscriptionId = dealerSubscriptionData.id;
          this.selectedEndDate(this.dealerSubscriptionData.planEndDate);
          this.selectedStartDate(this.dealerSubscriptionData.planStartDate);
        }
      });
      this.subscriptionDialog = true;
    },
    doAssignSubscription() {
      if (!this.selectedDealerSubscriptionId && this.$refs.form.validate()) {
        try {
          postAxios(
            `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-vs-dealer-subscription`,
            this.dealerSubscriptionData,
            this.token
          )
            .then(res => {
              if (res.data) {
                this.fetchDealerList();
                this.snackbar = true;
                this.snackMessage = "Subscription added Successfully!";
                // this.showAlert("success", "New Subscription Added!");
              }
            })
            .catch(err => {
              console.error(err);
              this.snackbar = true;
              this.snackMessage = "Error!";
            })
            .finally(() => {
              this.subscriptionDialog = false;
            });
        } catch (e) {
          console.error(e);
        }
      } else if (
        this.selectedDealerSubscriptionId &&
        this.$refs.form.validate()
      ) {
        try {
          this.dealerSubscriptionData.id = this.selectedDealerSubscriptionId;
          patchAxios(
            `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-vs-dealer-subscription/${this.selectedDealerSubscriptionId}`,
            this.dealerSubscriptionData,
            this.token
          )
            .then(res => {
              if (res.data) {
                this.fetchDealerList();
                this.snackbar = true;
                this.snackMessage = "Subscription updated Successfully!";
              }
            })
            .catch(err => {
              console.error(err);
              this.snackbar = true;
              this.snackMessage = "Error!";
            })
            .finally(() => {
              this.subscriptionDialog = false;
            });
        } catch (e) {
          console.error(e);
        }
      }
    },
    searchDealer() {
      this.fetchDealerList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchDealerList();
    },
    editButtonClick(item) {
      this.setEditItemInfo(item);
      this.editDialog = true;
    },
    editStatusButtonClick(item) {
      this.setEditItemInfo(item);

      this.shortcutEditDialog = true;
    },
    editCreditButtonClick(item) {
      this.setEditItemInfo(item);

      this.editCrshortcutEditDialog = true;
    },
    editPhotoButtonClick(item) {
      this.setEditItemInfo(item);
      this.editPhotoDialog = true;
    },
    editNameButtonClick(item) {
      this.setEditItemInfo(item);
      this.editNameDialog = true;
    },
    editDescriptionButtonClick(item) {
      this.setEditItemInfo(item);
      this.editDescriptionDialog = true;
    },
    setEditItemInfo(item) {
      this.editDealer.name = item.name;
      this.editDealer.status = item.status;
      this.editDealer.dealerId = item.id;
      this.editDealer.creditBalance = item.creditBalance;
      this.editDealer.description = item.description;
      this.editDealer.mobilePhone = item.mobilePhone;
      this.editDealer.address = item.address;
      this.editDealer.uenNum = item.uenNum;
      this.editDealer.hideCarFlag = item.hideCarFlag;
      this.editDealer.deactivationFlag = item.deactivationFlag;
      this.editDealer.authorizedFlag = item.authorizedFlag;
      this.primaryListingBannerDisplay = item.primaryListingBannerPath;
      this.mobileListingBannerDisplay = item.mobileListingBannerPath;
      this.ipadListingBannerDisplay = item.ipadListingBannerPath;
      this.secondaryListingBannerDisplay = item.secondaryListingBannerPath;
      this.tertiaryListingBannerDisplay = item.tertiaryListingBannerPath;
      this.editDealer.facebookUrl = item.facebookUrl;
      this.editDealer.websiteUrl = item.websiteUrl;
      this.editDealer.locality = item.locality;
      this.editDealer.operationalHrs = item.operationalHrs;
      this.editDealer.youtubeUrl = item.youtubeUrl;
      this.editDealer.instagramUrl = item.instagramUrl;
      this.editDealer.whatsappUrl = item.whatsappUrl;
      this.editDealer.bookTestDriveUrl = item.bookTestDriveUrl;
      this.editDealer.email = item.email;
      this.editDealer.priceListEffectiveDate = item.priceListEffectiveDate;
      this.priceListPathDisplay = item.priceListPath;
    },
    resetEditItemInfo() {
      this.editDealer.name = null;
      this.editDealer.status = null;
      this.editDealer.dealerId = null;
      this.editDealer.creditBalance = null;
      this.editDealer.logoPath = null;
      this.editDealer.description = null;
      this.editDealer.mobilePhone = null;
      this.editDealer.address = null;
      this.editDealer.uenNum = null;
      this.editDealer.hideCarFlag = null;
      this.editDealer.authorizedFlag = null;
      this.editDealer.primaryListingBannerPath = null;
      this.editDealer.mobileListingBannerPath = null;
      this.editDealer.ipadListingBannerPath = null;
      this.editDealer.secondaryListingBannerPath = null;
      this.editDealer.tertiaryListingBannerPath = null;
      this.editDealer.locality = null;
      this.editDealer.operationalHrs = null;
      this.editDealer.priceListPath = null;
      this.primaryListingBannerDisplay = null;
      this.mobileListingBannerDisplay = null;
      this.ipadListingBannerDisplay = null;
      this.secondaryListingBannerDisplay = null;
      this.tertiaryListingBannerDisplay = null;
      this.priceListPathDisplay = null;
      this.editDealer.youtubeUrl = null;
      this.editDealer.instagramUrl = null;
      this.editDealer.whatsappUrl = null;
      this.editDealer.bookTestDriveUrl = null;
      this.editDealer.priceListEffectiveDate = null;
      this.imgFile = null;
    },
    assignPlatformClick(item) {
      this.assignPlatformData.platformIds = item.platforms.map(p => p.id);
      this.assignPlatformData.name = item.name;
      this.assignPlatformData.dealerId = item.id;
      this.assignPlatformDialog = true;
    },
    assignDealerAssociatedTypesClick(item) {
      this.assignDealerTypeData.name = item.name;
      this.assignDealerTypeData.dealerId = item.id;
      this.assignDealerTypeDialog = true;
    },
    assignAccountManagerClick(item) {
      this.assignAccountManagerData.accountManagerId = item.accountManager;
      this.assignAccountManagerData.dealerId = item.id;
      this.assignAccountManagerDialog = true;
    },
    async doEditDealer() {
      const self = this;
      try {
        if (self.editDealer.priceListPath) {
          await uploadPriceListPdf(self.editDealer.priceListPath).then(res => {
            self.editDealer.priceListPath = res.data.data;
          });
        }
        if (self.imgFile) {
          const imgRes = await uploadImage(self.imgFile);
          self.editDealer.logoPath = imgRes.data.data;
        }
        if (self.editDealer.primaryListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.primaryListingBannerPath
          );
          self.editDealer.primaryListingBannerPath = imgRes.data.data;
        }
        if (self.editDealer.mobileListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.mobileListingBannerPath
          );
          self.editDealer.mobileListingBannerPath = imgRes.data.data;
        }
        if (self.editDealer.ipadListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.ipadListingBannerPath
          );
          self.editDealer.ipadListingBannerPath = imgRes.data.data;
        }
        if (self.editDealer.secondaryListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.secondaryListingBannerPath
          );
          self.editDealer.secondaryListingBannerPath = imgRes.data.data;
        }
        if (self.editDealer.tertiaryListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.tertiaryListingBannerPath
          );
          self.editDealer.tertiaryListingBannerPath = imgRes.data.data;
        }
        self.editDealer.hideCarFlag = self.editDealer.hideCarFlag ? 1 : 0;
        self.editDealer.deactivationFlag = self.editDealer.deactivationFlag
          ? 1
          : 0;
        self.editDealer.authorizedFlag = self.editDealer.authorizedFlag ? 1 : 0;
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          self.editDealer
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.shortcutEditDialog = false;
            self.editNameDialog = false;
            self.editDescriptionDialog = false;
            self.editCrshortcutEditDialog = false;
            self.editPhotoDialog = false;
            self.editDialog = false;
            self.fetchDealerList();
            self.resetEditItemInfo();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    deleteButtonClick(item) {
      this.delAdDealer.id = item.id;
      this.delAdDealer.name = item.name;
      this.deleteDialog = true;
    },
    doDelAdDealer() {
      const self = this;
      const param = {
        dealerId: self.delAdDealer.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/delDealer`,
        param,
        self.token
      ).then(res => {
        if (res.data) {
          self.deleteDialog = false;
          self.fetchDealerList();
        }
      });
    },
    fetchDealerList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.dealerSearch,
        status: self.dealerStatus,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer?isAuthorized=1`,
        params
      ).then(res => {
        if (res.data) {
          self.dealerList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    fetchPlatforms() {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/platform`)
        .then(res => {
          self.platformList = res.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    fetchDealerTypes() {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/baseDataSystem/dealerType`)
        .then(res => {
          self.dealerTypeList = res.data.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    fetchAccountManagers() {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/userSystem/accountManager`)
        .then(res => {
          self.accountManagerList = res.data.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    doAssignAccountManager() {
      const self = this;
      const { dealerId, accountManagerId } = this.assignAccountManagerData;
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/assignAccountManager`,
        {
          dealerId,
          accountManagerId,
        }
      )
        .then(() => {
          self.fetchDealerList();
        })
        .catch(err => {
          console.error(err);
          self.$emit("error", err);
        })
        .finally(() => {
          self.assignAccountManagerDialog = false;
        });
    },
    doAssignPlatforms() {
      const self = this;
      const { dealerId, platformIds } = this.assignPlatformData;
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/assignDealerPlatform`,
        {
          dealerId,
          platformIds,
        }
      )
        .then(() => {
          self.fetchDealerList();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.assignPlatformDialog = false;
        });
    },

    doAssignDealerTypes() {
      const self = this;
      const { dealerId, dealerTypeId } = this.assignDealerTypeData;
      const dealerTypes = dealerTypeId;
      putAxios(`${process.env.VUE_APP_SERVER_URL}/dealer/updDealerTypes`, {
        dealerId,
        dealerTypes,
      })
        .then(() => {
          self.fetchDealerList();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.assignDealerTypeDialog = false;
        });
    },
    showDealerCarBrands(dealerId) {
      this.fetchDealerCarBrands(dealerId);
      this.associatedCarBrandsDialog = true;
    },
    fetchDealerCarBrands(dealerId) {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealerInfo/associatedCarBrand?dealerId=${dealerId}`
      )
        .then(res => {
          self.dealerCarBrandsList = res.data.data;
          // self.dealerCarBrandsList.forEach(d => {
          //   self.dealerCarBrandsIdContext.push(d.carBrandId);
          // });
        })
        .catch(err => {
          console.error(err);
        });
    },
    addDealerCarBrand(selectedCarBrand) {
      this.dealerCarBrandsList.push({
        carBrandId: selectedCarBrand.id,
        carBrandName: selectedCarBrand.name,
        carBrandImgUrl: selectedCarBrand.logoPath,
      });
    },
    doUpdateDealerCarBrands(dealerId, dealerCarBrandsList) {
      const self = this;
      const carBrandId = dealerCarBrandsList.map(m => m.carBrandId);

      putAxios(`${process.env.VUE_APP_SERVER_URL}/dealer/updDealerCarBrands`, {
        dealerId,
        carBrandId,
      })
        .then(() => {
          self.fetchDealerCarBrands(self.editDealer.dealerId);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.associatedCarBrandsDialog = false;
        });
    },
    deleteFromCarBrandList(selected) {
      this.dealerCarBrandsList.forEach((d, i) => {
        if (d.carBrandId == selected.carBrandId) {
          this.dealerCarBrandsList.splice(i, 1);
        }
      });
    },
    async doAddDealer(addDealer) {
      const self = this;
      try {
        if (addDealer.logoPath) {
          const imgRes = await uploadImage(addDealer.logoPath);
          addDealer.logoPath = imgRes.data.data;
        }
        if (addDealer.primaryListingBannerPath) {
          const imgRes = await uploadImage(addDealer.primaryListingBannerPath);
          addDealer.primaryListingBannerPath = imgRes.data.data;
        }
        if (addDealer.mobileListingBannerPath) {
          const imgRes = await uploadImage(addDealer.mobileListingBannerPath);
          addDealer.mobileListingBannerPath = imgRes.data.data;
        }
        if (addDealer.ipadListingBannerPath) {
          const imgRes = await uploadImage(addDealer.ipadListingBannerPath);
          addDealer.ipadListingBannerPath = imgRes.data.data;
        }
        if (addDealer.secondaryListingBannerPath) {
          const imgRes = await uploadImage(
            addDealer.secondaryListingBannerPath
          );
          addDealer.secondaryListingBannerPath = imgRes.data.data;
        }
        if (addDealer.tertiaryListingBannerPath) {
          const imgRes = await uploadImage(addDealer.tertiaryListingBannerPath);
          addDealer.tertiaryListingBannerPath = imgRes.data.data;
        }
        addDealer.authorizedFlag = 1;
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/addDealer`,
          addDealer
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.addDialog = false;
            self.fetchDealerList();
            self.resetAddItemInfo();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    clickPriceList(priceListPath) {
      window.open(priceListPath);
    },
    onToggleHide(item) {
      const self = this;
      try {
        const data = {
          dealerId: item.id,
          hideCarFlag: item.hideCarFlag ? 1 : 0,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchDealerList();
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
